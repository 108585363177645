<template>
  <div class="card mb-5 mb-xl-10" v-loading="loading">
    <div class="card-body pt-9 pb-0">
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <div class="me-7 mb-4">
          <div
            class="
              symbol symbol-100px symbol-lg-160px symbol-fixed
              position-relative
            "
          >
            <img :src="artistImageURL" alt="image" />
            <div
              class="
                position-absolute
                translate-middle
                bottom-0
                start-100
                mb-6
                bg-success
                rounded-circle
                border border-4 border-white
                h-20px
                w-20px
              "
            ></div>
          </div>
        </div>
        <div class="flex-grow-1">
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <span class="text-gray-800 fs-2 fw-bolder me-1">{{
                  artistName
                }}</span>
                <a href="#" @click="saveArtist">
                  <span
                    :class="[
                      'svg-icon svg-icon-1',
                      isSavedInCrawlHistory === true ? 'svg-icon-primary' : '',
                    ]"
                  >
                    <inline-svg
                      src="
                        /media/icons/duotune/general/gen026.svg
                      "
                    />
                  </span>
                </a>
              </div>
            </div>
            <div class="d-flex my-4">
              <!--              <Follow-->
              <!--                v-model:isFollowed="isFollowed"-->
              <!--                :is-followed="isFollowed"-->
              <!--                :uuid="artistID.toString()"-->
              <!--              />-->
              <button
                class="btn btn-sm btn-primary me-3"
                @click="routeToSandboxPage(artistID)"
              >
                AI Sandbox
              </button>
              <ArtistMenuComponent
                :artist="{
                  uuid: artistID,
                  name: artistName,
                }"
                :from-drawer="true"
                :buttons="[
                  'discover',
                  'watchlist',
                  'crawl',
                  'crm',
                  'saveArtist',
                ]"
              />
            </div>
          </div>
          <ArtistHeaderOverView
            @updateGrowthRate="$emit('updateGrowthRate', $event)"
            :uuid="artistID.toString()"
            :total-playlists="totalPlaylists"
            :total-fanbase="totalFanbase"
          />
        </div>
      </div>
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          "
        >
          <li class="nav-item">
            <router-link
              :to="`/artists/${artistID}/overview`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Overview
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/artists/${artistID}/fanbase`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Fanbase
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/artists/${artistID}/listeners`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Listeners
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/artists/${artistID}/spotify`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Spotify
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/artists/${artistID}/playlist`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Playlist
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/artists/${artistID}/forecast`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Revenues & Forecast
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/artists/${artistID}/analytics`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Analytics
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getArtistByUUID } from "@/api/artists.api";
import ArtistHeaderOverView from "./ArtistHeaderOverView";
import ArtistMenuComponent from "./ArtistMenuComponent";
import { saveArtistApi, saveMongoArtistDetail } from "@/api/user.api";
import { getTotalPlaylistOnSpotify, scrapArtist } from "../../api/artists.api";

export default defineComponent({
  name: "BandInfo",
  components: {
    ArtistMenuComponent,
    ArtistHeaderOverView,
    // Follow,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const artistName = ref("");
    const artistImageURL = ref("/media/avatars/blank.png");
    const artistID = route.params.uuid;
    const addWatchlistModal = ref(null);
    const isFollowed = ref(false);
    const loading = ref(true);
    const totalPlaylists = ref(0);
    const isSavedInCrawlHistory = ref(false);
    const totalFanbase = computed(
      () => store.getters["ArtistModule/getTotalFanbase"]
    );

    onMounted(async () => {
      loading.value = true;
      const promises = [];
      promises.push(
        new Promise((resolve) => {
          getArtistByUUID(artistID)
            .then(({ data }) => {
              artistName.value = data.name;
              artistImageURL.value = data.imageUrl;
              isFollowed.value = data.isFollowed;
              isSavedInCrawlHistory.value = data.isSavedInCrawlHistory;
              // scrapArtist(artistName.value, artistID);
              saveArtist();
              saveMongoArtistDetail(artistID);
              resolve("");
            })
            .catch(() => resolve(""));
        })
      );
      promises.push(
        new Promise((resolve) => {
          getTotalPlaylistOnSpotify(artistID)
            .then(({ data }) => {
              totalPlaylists.value = data && data.data ? data.data : 0;
              resolve("");
            })
            .catch(() => resolve(""));
        })
      );
      await Promise.all(promises);
      loading.value = false;
    });

    const saveArtist = async () => {
      try {
        loading.value = true;
        if (isSavedInCrawlHistory.value === false) {
          await saveArtistApi(artistID);
          isSavedInCrawlHistory.value = !isSavedInCrawlHistory.value;
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const redirectToCrawler = () => {
      window.open(`/crawler?search=${artistName.value}`, "_blank");
    };
    const routeToSandboxPage = (uuid) => {
      window.open(`${process.env.VUE_APP_SANDBOX_URI}/?uuid=${uuid}`, "_blank");
    };
    const showModal = () => {
      store.commit(
        "ArtistModule/SET_SHOW_WATCHLIST_MODAL_ARTIST_UUID",
        artistID
      );
    };

    return {
      addWatchlistModal,
      loading,
      artistName,
      artistImageURL,
      artistID,
      isFollowed,
      totalFanbase,
      isSavedInCrawlHistory,
      totalPlaylists,
      saveArtist,
      showModal,
      redirectToCrawler,
      routeToSandboxPage,
    };
  },
});
</script>
